// The order of the locales defines the default value, if a locale
// needs to be fetched only by the language. e.g. "de" leads to "de-DE"
export const otherLocales = [
  'hi-IN',
  'ta-IN',
  'es-MX',
  'sr-Cyrl-RS',
  'sr-Cyrl-ME',
] as const;

export type OtherLocale = (typeof otherLocales)[number];
