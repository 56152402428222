import { toBounds } from '../geometry/Bounds';
import { Point } from '../geometry/Point';
/* eslint-disable @typescript-eslint/naming-convention */
import { LatLng } from '../LatLng';
import { IProjection } from './IProjection';

export const LonLat: IProjection = {
  project: function (latlng) {
    return new Point(latlng.lng, latlng.lat);
  },

  unproject: function (point) {
    return new LatLng(point.y, point.x);
  },

  bounds: toBounds([-180, -90], [180, 90]),
};
