import { Point } from './Point';

/**
 * Beschreibt Grenzen, die durch zwei Eckpunkte im zweidimensionalen Raum definiert sind
 */
export class Bounds {
  min!: Point;
  max!: Point;

  /**
   * @param {Point} points Menge von Punkten, die in diesen Grenzen enthalten sind
   */
  constructor(...points: Point[]) {
    for (const p of points) {
      this.extend(p);
    }
  }

  /**
   * Erweitert diese Grenzen und fügt den übergebenen Punkt mit hinzu
   * @param {Point} p
   */
  extend(p: Point): void {
    if (this.min == null || this.max == null) {
      this.min = p.clone();
      this.max = p.clone();
    } else {
      this.min.x = Math.min(this.min.x, p.x);
      this.min.y = Math.min(this.min.y, p.y);

      this.max.x = Math.max(this.max.x, p.x);
      this.max.y = Math.max(this.max.y, p.y);
    }
  }

  /**
   * Gibt die Größe der Grenzen zurück
   * @returns {Point}
   */
  get size(): Point {
    return new Point(this.max.x - this.min.x, this.max.y - this.min.y);
  }

  /**
   * Gibt das Zentrum der Grenzen zurück
   * @returns {Point}
   */
  get center(): Point {
    return new Point(
      (this.max.x + this.min.x) / 2,
      (this.max.y + this.min.y) / 2,
    );
  }

  /**
   * Prüft, ob ein Punkt innerhalb dieser Grenzen liegt
   * @param {Point} p
   * @returns {boolean}
   */
  contains(p: Point): boolean {
    return (
      this.min.x <= p.x &&
      this.max.x >= p.x &&
      this.min.y <= p.y &&
      this.max.y >= p.y
    );
  }

  /**
   * Multipliziert die Eckpunkte dieser Grenzen mit dem übergebenen Faktor
   * @param {number} n
   * @returns {this}
   */
  multiplyBy(n: number): this {
    this.min._multiplyBy(n);
    this.max._multiplyBy(n);
    return this;
  }

  /**
   * Dividiert die Eckpunkte dieser Grenzen durch den übergebenen Divisor
   * @param {number} n
   * @returns {this}
   */
  divideBy(n: number): this {
    this.min._divideBy(n);
    this.max._divideBy(n);
    return this;
  }

  /**
   * Gibt den oberen, linken Eckpunkt zurück
   * @returns {Point}
   */
  get topLeft(): Point {
    return this.min.clone();
  }

  /**
   * Gibt den unteren, linken Eckpunkt zurück
   * @returns {Point}
   */
  get bottomLeft(): Point {
    return new Point(this.min.x, this.max.y);
  }

  /**
   * Gibt den unteren, rechten Eckpunkt zurück
   * @returns {Point}
   */
  get bottomRight(): Point {
    return this.max.clone();
  }

  /**
   * Gibt den oberen, rechten Eckpunkt zurück
   * @returns {Point}
   */
  get topRight(): Point {
    return new Point(this.max.x, this.min.y);
  }

  /**
   * Prüft, ob diese Grenzen die übergebenen Grenzen schneiden
   * @param {Bounds} bounds
   * @returns {boolean}
   */
  intersects(bounds: Bounds): boolean {
    const min = this.min,
      max = this.max,
      min2 = bounds.min,
      max2 = bounds.max,
      xIntersects = max2.x >= min.x && min2.x <= max.x,
      yIntersects = max2.y >= min.y && min2.y <= max.y;

    return xIntersects && yIntersects;
  }

  /**
   * Prüft, ob diese Grenzen komplett in den übergebenen Grenzen enthalten sind
   * @param {Bounds} bounds
   * @returns {boolean}
   */
  overlaps(bounds: Bounds): boolean {
    const min = this.min,
      max = this.max,
      min2 = bounds.min,
      max2 = bounds.max,
      xOverlaps = max2.x > min.x && min2.x < max.x,
      yOverlaps = max2.y > min.y && min2.y < max.y;

    return xOverlaps && yOverlaps;
  }

  /**
   * @returns {boolean} <b>false</b>, wenn diese Grenzen noch nicht mit Punkten initialisiert wurden
   */
  isValid(): boolean {
    return this.min != null && this.max != null;
  }

  /**
   * @returns {string} String-Repräsentation dieser Grenzen
   */
  toString(): string {
    return `[${this.min.toString()},${this.max.toString()}]`;
  }
}

export type BoundsLike =
  | Bounds
  | [[number, number], [number, number]]
  | [number, number];

export function toBounds(a: BoundsLike, b?: [number, number]): Bounds {
  if (a instanceof Bounds) {
    return a;
  } else if (Array.isArray(a)) {
    if (Array.isArray(b) && typeof a[0] === 'number') {
      a = a as [number, number];
      return new Bounds(new Point(a[0], a[1]), new Point(b[0], b[1]));
    } else if (Array.isArray(a[0])) {
      a = a as [[number, number], [number, number]];
      return new Bounds(
        new Point(a[0][0], a[0][1]),
        new Point(a[1][0], a[1][1]),
      );
    }
  }
  throw new Error('No valid input given for Bounds object');
}
