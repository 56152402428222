import { SupportedLanguage } from '@pwa/localization/supported-languages';

import { inbentaInstances } from './inbenta-instances';
import { InbentaKmSDK } from './inbenta.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const InbentaKmSDK: InbentaKmSDK;

type TranslationFunction = (key: string) => string;

export async function loadInbentaConf(
  translations: Record<string, string>,
  locale: SupportedLanguage,
): Promise<void> {
  const translate: TranslationFunction = (key: string) =>
    translations[key] ?? key;

  // Create CSS core file
  const inbScriptCSS = document.createElement('link');
  inbScriptCSS.type = 'text/css';
  inbScriptCSS.rel = 'stylesheet';
  inbScriptCSS.href = 'assets/inbenta-core.css';
  document.head.appendChild(inbScriptCSS);

  // Create JS core file
  const inbScriptJS = document.createElement('script');
  inbScriptJS.src = 'assets/inbenta-core.js';
  let waitForScriptResolve: () => void;
  const waitForScriptPromise = new Promise<void>((resolve) => {
    waitForScriptResolve = resolve;
  });
  inbScriptJS.onload = () => waitForScriptResolve();
  document.head.appendChild(inbScriptJS);
  await waitForScriptPromise;

  const sdk = InbentaKmSDK.createFromDomainKey(
    inbentaInstances[locale]?.domainKey || '',
    inbentaInstances[locale]?.publicKey || '',
    {
      skin: 'none',
      labels: translationsToInbentaLabels(translations),
    },
  );

  const searchBox = sdk.component('searchBox', '#search-box');
  const results = sdk.component('results', '#results', {
    ratings: {
      elements: [
        {
          id: 1,
          label: 'yes', // will only show icon, no translation needed
          comment: false,
          response: translate('faq_response'),
        },
        {
          id: 2,
          label: 'no', // will only show icon, no translation needed
          comment: true,
          response: translate('faq_response'),
        },
      ],
    },
  });
  const autocompleter = sdk.component('autocompleter', '#autocompleter');
  autocompleter.linkToSearchBox(searchBox);
  results.linkToAutocompleter(autocompleter);
  results.linkToSearchBox(searchBox);
  // sdk.component('popular', '#popular');
  sdk.component('categories', '#categories');
  // const categoriesBreadcrumb = sdk.component(
  //   'categoriesBreadcrumb',
  //   '#categories-breadcrumb',
  // );
  // categories.linkToCategoriesBreadCrumb(categoriesBreadcrumb);
}

function translationsToInbentaLabels(
  translations: Record<string, string>,
): Record<string, string> {
  const out = Object.fromEntries(
    Object.entries(translations)
      .filter(([key]) => key.startsWith('faq_'))
      .map(([key, value]) => [key.replace('faq_', '').toUpperCase(), value]),
  );
  const toCopy: [from: string, to: string][] = [
    ['POPULAR_CATEGORIES_TITLE', 'CATEGORIES_TITLE'],
    ['RESULTS_BACK_BUTTON', 'CATEGORIES_BACK_BUTTON'],
  ];
  for (const [from, to] of toCopy) {
    out[to] = out[from];
  }
  return out;
}
