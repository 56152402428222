import { LatLng } from '../LatLng';
import { NumberRange } from '../Util';

export const EARTH_R = 6371000;
export const EARTH_WRAP_LNG: NumberRange = [-180, 180];

export function earthDistance(latlng1: LatLng, latlng2: LatLng): number {
  const rad = Math.PI / 180,
    lat1 = latlng1.lat * rad,
    lat2 = latlng2.lat * rad,
    a =
      Math.sin(lat1) * Math.sin(lat2) +
      Math.cos(lat1) *
        Math.cos(lat2) *
        Math.cos((latlng2.lng - latlng1.lng) * rad);

  return EARTH_R * Math.acos(Math.min(a, 1));
}
