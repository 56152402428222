// The order of the locales defines the default value, if a locale
// needs to be fetched only by the language. e.g. "fr" leads to "fr-FR"
export const pwaLocales = [
  'bg-BG',
  'bs-BA',
  'cs-CZ',
  'da-DK',
  'de-DE',
  'de-AT',
  'de-CH',
  'el-GR',
  'en-GB',
  'en-IE',
  'en-IN',
  'en-US',
  'es-ES',
  'es-AR',
  'es-US',
  'fr-FR',
  'fr-BE',
  'fr-CH',
  'hr-HR',
  'hu-HU',
  'it-IT',
  'lt-LT',
  'lv-LV',
  'mk-MK',
  'nl-NL',
  'nl-BE',
  'pl-PL',
  'pt-PT',
  'pt-BR',
  'ro-RO',
  'ru-UA',
  'sk-SK',
  'sl-SI',
  'sr-Latn-RS',
  'sr-Latn-ME',
  'tr-TR',
  'uk-UA',
] as const;

export type PwaLocale = (typeof pwaLocales)[number];
