import { SupportedLanguage } from '@pwa/localization/supported-languages';

export type InstanceKeys = { publicKey: string; domainKey: string };

export type InbentaInstances = {
  [Language in SupportedLanguage]?: InstanceKeys;
};

export const inbentaInstances: InbentaInstances = {
  cs: {
    publicKey: 'BhkY9nE0Sd2V/8X37RWGpUGVC1htvCKli+a2SSKGScM=',
    domainKey:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJwcm9qZWN0Ijoid2V0dGVyb25saW5lX2E3NDZfa21fY3MiLCJkb21haW5fa2V5X2lkIjoiQmh1MktfMDZpbF80czhfd2tDRjk3UTo6In0.p496rV1nQSUc-FHx3SjUH5rpwIZ1Axl8vmaOJDo7CqA8Xv2qm6zXj6ZjNzs1WGAtYhV7Dt5Vftwaj9uRrO_u6w',
  },
  en: {
    publicKey: 'Be/Ia1JINVCDdLA6Bcv8ICRXw0UApi53Ea+oVFlmIMk=',
    domainKey:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJwcm9qZWN0Ijoid2V0dGVyb25saW5lX2ttX2VuIiwiZG9tYWluX2tleV9pZCI6IkJoelBrZXF5WW1jNV90M2tlVG5BSXc6OiJ9.RPfWjauezPWt2cOJCzldf32sd4YH9FPne5t4POdf7B1E4VjurfJ5VahFHxDKKUAxH3ucvFKfHUU1FkypTxP3Jw',
  },
  es: {
    publicKey: 'Be/Ieucsk9BQi3g2EZ1gN3qSBNlidUuYCCkjfY7gKxM=',
    domainKey:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJwcm9qZWN0Ijoid2V0dGVyb25saW5lX2ttX2VzIiwiZG9tYWluX2tleV9pZCI6IkJoMlpGY216Z3hjb0l0V2ZqNll0WXc6OiJ9.Y86nq1ZO23E0OKGjE3Gqk456afjNwjVGsAjETOZIrvD0R-FiAgq-omPjcrOM32AXWWykoAZMKaXm8oKJTG9qaQ',
  },
  de: {
    publicKey: 'BV6j3h15BaDksR5R1Bw43LxHwee+fexel+H4wQzRgng=',
    domainKey:
      'eyJ0eXBlIjoiSldUIiwiYWxnIjoiUlMyNTYifQ.eyJwcm9qZWN0Ijoid2V0dGVyb25saW5lX2RlIiwiZG9tYWluX2tleV9pZCI6IkJZRFJ4X0xEMTR3bnZycTRHNGE5RVE6OiJ9.JEeJmZ_hK9C6-LedOLI3GOgfeUT0OnYhjYJdAcGTxNsyIFIS5RzMAfaLjAfzFP1LrfgfLwRiW9Uzfnck2GU7-Q',
  },
  fr: {
    publicKey: 'BhkZOzIu5+R8dvn7BuOWYHnKiV3Gv/IVmUQp9gL9mzw=',
    domainKey:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJwcm9qZWN0Ijoid2V0dGVyb25saW5lXzFhMzVfa21fZnIiLCJkb21haW5fa2V5X2lkIjoiQmgwTUFPRmVQUVplYU5EbTlFQXAtdzo6In0.WJSedUu5qKYjlTRoy2VQvjs3k01bzQSZ83seABg_iJeAJifxLsBipNiJx3kAYomrtoI4HrFMPClhbMxJW1c5rA',
  },
  hu: {
    publicKey: 'BhkYwfPhIdVuwedFlEQAnsrYHKuYGvxU1HlT2yHot6w=',
    domainKey:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJwcm9qZWN0Ijoid2V0dGVyb25saW5lXzljOTBfa21faHUiLCJkb21haW5fa2V5X2lkIjoiQmgwTC00UDFDMDRtbGhrQUFmRW9Ldzo6In0.awQN7Zk62OwQXx-HhVDtQFEXtcARZp8_bMQcE1ZGdxhXm9KLwPzXHivooEVNA2z8ZlsoealmF-IL8ZDY28L5Gg',
  },
  it: {
    publicKey: 'Bhx9ZCc5qnjUxx0mPfJ3kFvdaWcrp4SDtt6gplaMaa4=',
    domainKey:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJwcm9qZWN0Ijoid2V0dGVyb25saW5lX2JiNzhfa21faXQiLCJkb21haW5fa2V5X2lkIjoiQmgyWkdkcHk0VUdtSFlrU0hiV2ZOUTo6In0.p00KjNATqunZnUdvttYL_XcqNAaooGWc2PAZduuCPGiYU5s4UK4uvHBXgryMVjXdEsAXCAN5S98Ivt9jM8vJYg',
  },
  nl: {
    publicKey: 'Bhx8e3fg+i3NmVTyf7V2iU7+jTg/foHmro/bmLbLMns=',
    domainKey:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJwcm9qZWN0Ijoid2V0dGVyb25saW5lX2U1ZmJfa21fbmwiLCJkb21haW5fa2V5X2lkIjoiQmgyWkhsYjBLNWROSHh6WUgwUE5IQTo6In0.R0L1XUYeQ_2yDV1pOe8i3F_ersqef2wny3xYiP2R1ojiuLEJkR-9GKtS63X4Jln5RnDhcIc3UpXK6iyWP172hA',
  },
  pl: {
    publicKey: 'BhxsBnCqlTItBb5SLXH8qzYFtlWLm1MWTclgNiPrQJY=',
    domainKey:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJwcm9qZWN0Ijoid2V0dGVyb25saW5lXzZmMjlfa21fcGwiLCJkb21haW5fa2V5X2lkIjoiQmgyWkpWSVU4RUpRNVdoVTJUenl3Zzo6In0.qJMbsQzTH1iuF0nkbIfsMsckTzXaxhAbHpy_xFY5syFOZNruTraficFaADLAiL9OK_FamlvxFCH5jPWtwK-xCA',
  },
  pt: {
    publicKey: 'BhkYglZMrX60eOHYLJ94ZJJUgjL79lQDesArhSfwM4A=',
    domainKey:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJwcm9qZWN0Ijoid2V0dGVyb25saW5lXzU2MDFfa21fcHQiLCJkb21haW5fa2V5X2lkIjoiQmh1alF4VlotQThuLTZ2cGsyYkdzQTo6In0.E08XWuPOOV4sqL3eQWQ-aSH_WIWwARxVXgnZYFyZxVwawdsd5AK5A3udwI8s0rJjKvhcVf6Wsazu7l7apVfzug',
  },
};
