import { Transformation } from '../geometry/Transformation';
import { LatLng } from '../LatLng';
import { LonLat } from '../projection/Projection.LonLat';
import { CRS } from './CRS';

export class CRSSimple extends CRS {
  projection = LonLat;
  transformation = new Transformation(1, 0, -1, 0);
  override infinite = true;

  override scale(zoom: number): number {
    return Math.pow(2, zoom);
  }

  override zoom(scale: number): number {
    return Math.log(scale) / Math.LN2;
  }

  distance(latlng1: LatLng, latlng2: LatLng): number {
    const dx = latlng2.lng - latlng1.lng,
      dy = latlng2.lat - latlng1.lat;

    return Math.sqrt(dx * dx + dy * dy);
  }
}
