import { Bounds } from '../geometry/Bounds';
import { Point } from '../geometry/Point';
import { Transformation } from '../geometry/Transformation';
import { LatLng } from '../LatLng';
import { LatLngBounds } from '../LatLngBounds';
import { IProjection } from '../projection/IProjection';
import { NumberRange, wrapNum } from '../Util';

export abstract class CRS {
  protected abstract transformation: Transformation;
  abstract projection: IProjection;
  // PATHFINDER: if you find me, fix me by adjusting the member ordering! (first private, then protected, then public)
  // If that ordering feels weird, think about the overall classes structure.
  // Maybe something needs to be refactored.
  // eslint-disable-next-line @typescript-eslint/member-ordering
  protected wrapLng?: NumberRange;
  // PATHFINDER: if you find me, fix me by adjusting the member ordering! (first private, then protected, then public)
  // If that ordering feels weird, think about the overall classes structure.
  // Maybe something needs to be refactored.
  // eslint-disable-next-line @typescript-eslint/member-ordering
  protected wrapLat?: NumberRange;

  infinite = false;

  latLngToPoint(latlng: LatLng, zoom = 0): Point {
    const projectedPoint = this.projection.project(latlng),
      scale = this.scale(zoom);

    return this.transformation._transform(projectedPoint, scale);
  }

  pointToLatLng(point: Point, zoom = 0): LatLng {
    const scale = this.scale(zoom),
      untransformedPoint = this.transformation.untransform(point, scale);

    return this.projection.unproject(untransformedPoint);
  }

  project(latlng: LatLng): Point {
    return this.projection.project(latlng);
  }

  unproject(point: Point): LatLng {
    return this.projection.unproject(point);
  }

  scale(zoom: number): number {
    return 256 * Math.pow(2, zoom);
  }

  zoom(scale: number): number {
    return Math.log(scale / 256) / Math.LN2;
  }

  getProjectedBounds(zoom: number): Bounds | null {
    if (this.infinite) {
      return null;
    }

    const b = this.projection.bounds,
      s = this.scale(zoom),
      min = this.transformation.transform(b.min, s),
      max = this.transformation.transform(b.max, s);

    return new Bounds(min, max);
  }

  wrapLatLng(latlng: LatLng): LatLng {
    const lng = this.wrapLng
        ? wrapNum(latlng.lng, this.wrapLng, true)
        : latlng.lng,
      lat = this.wrapLat ? wrapNum(latlng.lat, this.wrapLat, true) : latlng.lat;

    return new LatLng(lat, lng, latlng.alt);
  }

  wrapLatLngBounds(bounds: LatLngBounds): LatLngBounds {
    const center = bounds.getCenter(),
      newCenter = this.wrapLatLng(center),
      latShift = center.lat - newCenter.lat,
      lngShift = center.lng - newCenter.lng;

    if (latShift === 0 && lngShift === 0) {
      return bounds;
    }

    const sw = bounds.southWest,
      ne = bounds.northEast,
      newSw = new LatLng(sw.lat - latShift, sw.lng - lngShift),
      newNe = new LatLng(ne.lat - latShift, ne.lng - lngShift);

    return new LatLngBounds(newSw, newNe);
  }

  abstract distance(latlng1: LatLng, latlng2: LatLng): number;
}
