import { Point } from './Point';

export class Transformation {
  constructor(
    private a: number,
    private b: number,
    private c: number,
    private d: number,
  ) {}

  transform(p: Point, scale?: number): Point {
    return this._transform(p.clone(), scale);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _transform(p: Point, scale = 1): Point {
    p.x = scale * (this.a * p.x + this.b);
    p.y = scale * (this.c * p.y + this.d);
    return p;
  }

  untransform(p: Point, scale = 1): Point {
    return new Point(
      (p.x / scale - this.b) / this.a,
      (p.y / scale - this.d) / this.c,
    );
  }
}
