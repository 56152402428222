export function formatNum(num: number, digits = 6): number {
  const pow = 10 ** digits;
  return Math.round(num * pow) / pow;
}

export type NumberRange = [number, number];

export function wrapNum(
  x: number,
  range: NumberRange,
  includeMax?: boolean,
): number {
  const max = range[1],
    min = range[0],
    d = max - min;
  return x === max && includeMax ? x : ((((x - min) % d) + d) % d) + min;
}
