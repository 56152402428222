import {
  SupportedLanguage,
  supportedLanguages,
} from '@pwa/localization/supported-languages';
import { loadLanguage } from '@pwa/translations/src/lib/faq';
import { Locale } from '@wetteronline/pwa-locale';

import { inbentaInstances } from './inbenta-instances';

function determineLocale(): { language: SupportedLanguage; region: string } {
  const url = new URL(location.href);
  const queryLocale = url.searchParams.get('locale');

  if (queryLocale) {
    if (checkLocaleString(queryLocale)) {
      return checkLocaleString(queryLocale) as {
        language: SupportedLanguage;
        region: string;
      };
    }
  }

  for (const navigatorLocale of navigator.languages) {
    if (checkLocaleString(navigatorLocale)) {
      return checkLocaleString(navigatorLocale) as {
        language: SupportedLanguage;
        region: string;
      };
    }
  }

  return { language: 'de', region: 'DE' };
}

function checkLocaleString(
  localeString: string,
): { language: SupportedLanguage; region: string } | undefined {
  const locale = new Locale(localeString);
  const lang = locale.langWithCharSet;
  const region = locale.region;
  const langWithout = locale.langWithoutCharSet;

  if (
    (supportedLanguages as string[]).includes(localeString) &&
    localeString in inbentaInstances
  ) {
    let defaultRegion = '';
    switch (localeString) {
      case 'en':
        defaultRegion = 'GB';
        break;
      case 'de':
        defaultRegion = 'DE';
        break;
      case 'es':
        defaultRegion = 'ES';
        break;
      case 'pt':
        defaultRegion = 'PT';
        break;

      default:
        defaultRegion = region;
    }
    return {
      language: localeString as SupportedLanguage,
      region: defaultRegion,
    };
  }

  if (
    (supportedLanguages as string[]).includes(lang) &&
    lang in inbentaInstances
  ) {
    return { language: lang as SupportedLanguage, region: region };
  }
  if (
    (supportedLanguages as string[]).includes(langWithout) &&
    langWithout in inbentaInstances
  ) {
    return { language: langWithout as SupportedLanguage, region: region };
  }
}

export const locale = determineLocale();
export const translationsPromise = loadLanguage(locale.language);
