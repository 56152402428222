// Hint: Recipes are grouped depending on their required parameters. Recipes
// that share the same parameter structure, can be grouped into one parameter
// group (these groupes are not visible to the outside, but they are useful for
// the internals of this lib)
// There are two fallback groups: randomRecipes are recipes where grouping would
// be pointless, because their parameter structures are unique and there are no
// other recipes that share the same parameter structure. Remember to form a
// group, if new recipes are added and they share the same parameters as one of
// the randomRecipes.
// The other fallback group are pureRecipes. These recipes don't have any
// parameters at all.

export const placemarkRecipes = [
  'airQuality',
  'pollen',
  'ski',
  'uvIndex',
  'weather',
  'warningMap',
] as const;
export type PlacemarkRecipe = (typeof placemarkRecipes)[number];

export const mapIdRecipes = ['weatherSymbolMap'] as const;
export type MapIdRecipe = (typeof mapIdRecipes)[number];

/**
 * Groups all recipes that have unique configuration and therefore any other
 * kind of grouping would be pointless
 */
export const randomRecipes = [
  'radar',
  'article',
  'productMarketingArticle',
  'productMarketingRoot',
  'teaser',
  'authors',
] as const;
export type RandomRecipe = (typeof randomRecipes)[number];

/**
 * Groups all recipes that have no configuration at all
 */
export const pureRecipes = [
  'apps',
  'debug',
  'editorialError',
  'editorialRoot',
  'error',
  'faq',
  'home',
  'imprint',
  'notFound',
  'privacy',
  'stream',
  'trend',
  'uvIndex',
  'weatherWidget',
] as const;
export type PureRecipe = (typeof pureRecipes)[number];

export type UrlRecipe =
  | PlacemarkRecipe
  | MapIdRecipe
  | RandomRecipe
  | PureRecipe;
