export class Locale {
  readonly full: string;
  readonly withoutCharSet: string;
  readonly langWithCharSet: string;
  readonly langWithoutCharSet: string;
  readonly region: string;

  constructor(locale: string) {
    const split = locale.split('-');
    const hasCharSet = split.length >= 3;
    this.withoutCharSet = hasCharSet ? `${split[0]}-${split[2]}` : locale;
    this.langWithCharSet = hasCharSet ? `${split[0]}-${split[1]}` : split[0];
    this.langWithoutCharSet = split[0];
    this.region = split[split.length - 1];
    this.full = locale;
  }
}
