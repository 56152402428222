import { toBounds } from '../geometry/Bounds';
import { Point } from '../geometry/Point';
/* eslint-disable @typescript-eslint/naming-convention */
import { LatLng } from '../LatLng';
import { IProjection } from './IProjection';

export interface ISphericalMercatorProjection extends IProjection {
  R: number;
  MAX_LATITUDE: number;
}

export const SphericalMercator: ISphericalMercatorProjection = {
  R: 6378137,
  MAX_LATITUDE: 85.0511287798,

  project: function (latlng) {
    const d = Math.PI / 180;
    const max = this.MAX_LATITUDE;
    const lat = Math.max(Math.min(max, latlng.lat), -max),
      sin = Math.sin(lat * d);

    return new Point(
      this.R * latlng.lng * d,
      (this.R * Math.log((1 + sin) / (1 - sin))) / 2,
    );
  },

  unproject: function (point) {
    const d = 180 / Math.PI;

    return new LatLng(
      (2 * Math.atan(Math.exp(point.y / this.R)) - Math.PI / 2) * d,
      (point.x * d) / this.R,
    );
  },

  bounds: (function () {
    const d = 6378137 * Math.PI;
    return toBounds([-d, -d], [d, d]);
  })(),
};
