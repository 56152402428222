import { CRSEarth } from './CRS.Earth';
import { Mercator } from '../projection/Projection.Mercator';
import { Transformation } from '../geometry/Transformation';

export class CRSEPSG3395 extends CRSEarth {
  projection = Mercator;
  transformation = (() => {
    const scale = 0.5 / (Math.PI * Mercator.R);
    return new Transformation(scale, 0.5, -scale, 0.5);
  })();
}
