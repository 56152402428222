// this file is automatically generated by the script npm run update-languages
// do not edit this file manually, but run the script instead, if there are language updates
export type SupportedLanguage =
  | 'bg'
  | 'bs'
  | 'cs'
  | 'da'
  | 'de-AT'
  | 'de-CH'
  | 'de'
  | 'el'
  | 'en-GB'
  | 'en-IE'
  | 'en-IN'
  | 'en'
  | 'es-US'
  | 'es'
  | 'fr'
  | 'hi'
  | 'hr'
  | 'hu'
  | 'it'
  | 'lt'
  | 'lv'
  | 'mk'
  | 'nl'
  | 'pl'
  | 'pt-BR'
  | 'pt'
  | 'ro'
  | 'ru'
  | 'sk'
  | 'sl'
  | 'sr-Latn'
  | 'sr'
  | 'ta'
  | 'tr'
  | 'uk';

export const supportedLanguages: SupportedLanguage[] = [
  'bg',
  'bs',
  'cs',
  'da',
  'de-AT',
  'de-CH',
  'de',
  'el',
  'en-GB',
  'en-IE',
  'en-IN',
  'en',
  'es-US',
  'es',
  'fr',
  'hi',
  'hr',
  'hu',
  'it',
  'lt',
  'lv',
  'mk',
  'nl',
  'pl',
  'pt-BR',
  'pt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sr-Latn',
  'sr',
  'ta',
  'tr',
  'uk',
];
