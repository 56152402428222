import { Url } from './url';

export class PwaUrl implements Url {
  static fromString(urlString: string): PwaUrl {
    const url = new URL(urlString);
    const segments = url.pathname.split('/');
    const queryParams: Record<string, string> = {};
    url.searchParams.forEach((value, key) => (queryParams[key] = value));
    return new PwaUrl({
      segments,
      queryParams,
    });
  }

  readonly pathSegments: string[];
  readonly path: string;
  readonly root: string;
  readonly leaf: string;
  readonly queryParams: Record<string, string>;
  readonly ngRouterArgs: [string[], { queryParams: Record<string, string> }];
  readonly fullUrl: string;

  // PATHFINDER: if you find me, fix me by adjusting the member ordering! (first private, then protected, then public)
  // If that ordering feels weird, think about the overall classes structure.
  // Maybe something needs to be refactored.
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private readonly queryParamSorter?: (a: string, b: string) => number;

  constructor(opts: {
    segments: (string | null | undefined)[];
    queryParams?: Record<string, string | null | undefined>;
    queryParamSorter?: (a: string, b: string) => number;
  }) {
    this.pathSegments = opts.segments.filter(
      (segment): segment is string => segment != null,
    );

    this.path = this.pathSegments.join('/');

    const meaningfulSegments = this.pathSegments.filter((s) => s.length > 0);
    this.root = meaningfulSegments.shift() ?? '';
    this.leaf = meaningfulSegments.pop() ?? '';

    this.queryParams = Object.fromEntries(
      Object.entries(opts.queryParams ?? {}).filter(
        (entry): entry is [string, string] => entry[1] != null,
      ),
    );

    this.queryParamSorter = opts.queryParamSorter;

    this.ngRouterArgs = [
      this.pathSegments,
      {
        queryParams: this.queryParams,
      },
    ];

    const qpEntries = Object.entries(this.queryParams).map((entry) =>
      entry.join('='),
    );

    if (this.queryParamSorter) {
      const sorter = this.queryParamSorter;
      qpEntries.sort((a, b) => sorter(a, b));
    } else {
      qpEntries.sort();
    }

    this.fullUrl =
      this.path + (qpEntries.length > 0 ? `?${qpEntries.join('&')}` : '');
  }

  pathStartsWith(other: Url): boolean {
    const thisPath = this.path;
    const otherPath = other.path;
    return thisPath === otherPath || thisPath.startsWith(otherPath + '/');
  }
}
