import { LatLng } from './LatLng';

export class LatLngBounds {
  private _southWest!: LatLng;
  private _northEast!: LatLng;

  constructor(corner1?: LatLng[] | LatLng, corner2?: LatLng) {
    if (corner1) {
      if (Array.isArray(corner1)) {
        for (const corner of corner1) {
          this.extend(corner);
        }
      } else {
        this.extend(corner1);
      }
    }
    if (corner2) {
      this.extend(corner2);
    }
  }

  extend(obj: LatLng | LatLngBounds): this {
    const sw = this._southWest;
    const ne = this._northEast;
    let sw2: LatLng;
    let ne2: LatLng;

    if (obj instanceof LatLng) {
      sw2 = obj;
      ne2 = obj;
    } else if (obj instanceof LatLngBounds) {
      sw2 = obj._southWest;
      ne2 = obj._northEast;
    } else {
      throw new Error('Invalid object given to method extend');
    }

    if (sw == null && ne == null) {
      this._southWest = sw2.clone();
      this._northEast = ne2.clone();
    } else {
      sw.lat = Math.min(sw2.lat, sw.lat);
      sw.lng = Math.min(sw2.lng, sw.lng);
      ne.lat = Math.max(ne2.lat, ne.lat);
      ne.lng = Math.max(ne2.lng, ne.lng);
    }

    return this;
  }

  pad(bufferRatio: number): LatLngBounds {
    const sw = this._southWest,
      ne = this._northEast,
      heightBuffer = Math.abs(sw.lat - ne.lat) * bufferRatio,
      widthBuffer = Math.abs(sw.lng - ne.lng) * bufferRatio;

    return new LatLngBounds(
      new LatLng(sw.lat - heightBuffer, sw.lng - widthBuffer),
      new LatLng(ne.lat + heightBuffer, ne.lng + widthBuffer),
    );
  }

  getCenter(): LatLng {
    return new LatLng(
      (this._southWest.lat + this._northEast.lat) / 2,
      (this._southWest.lng + this._northEast.lng) / 2,
    );
  }

  get southWest(): LatLng {
    return this._southWest.clone();
  }

  get northEast(): LatLng {
    return this._northEast.clone();
  }

  get northWest(): LatLng {
    return new LatLng(this.north, this.west);
  }

  get southEast(): LatLng {
    return new LatLng(this.south, this.east);
  }

  get west(): number {
    return this._southWest.lng;
  }

  get south(): number {
    return this._southWest.lat;
  }

  get east(): number {
    return this._northEast.lng;
  }

  get north(): number {
    return this._northEast.lat;
  }

  contains(obj: LatLng | LatLngBounds): boolean {
    const sw = this._southWest,
      ne = this._northEast;
    let sw2, ne2;

    if (obj instanceof LatLngBounds) {
      sw2 = obj._southWest;
      ne2 = obj._northEast;
    } else {
      sw2 = ne2 = obj;
    }

    return (
      sw2.lat >= sw.lat &&
      ne2.lat <= ne.lat &&
      sw2.lng >= sw.lng &&
      ne2.lng <= ne.lng
    );
  }

  intersects(bounds: LatLngBounds): boolean {
    const sw = this._southWest,
      ne = this._northEast,
      sw2 = bounds._southWest,
      ne2 = bounds._northEast,
      latIntersects = ne2.lat >= sw.lat && sw2.lat <= ne.lat,
      lngIntersects = ne2.lng >= sw.lng && sw2.lng <= ne.lng;

    return latIntersects && lngIntersects;
  }

  overlaps(bounds: LatLngBounds): boolean {
    const sw = this._southWest,
      ne = this._northEast,
      sw2 = bounds._southWest,
      ne2 = bounds._northEast,
      latOverlaps = ne2.lat > sw.lat && sw2.lat < ne.lat,
      lngOverlaps = ne2.lng > sw.lng && sw2.lng < ne.lng;

    return latOverlaps && lngOverlaps;
  }

  toBBoxString(): string {
    return [this.west, this.south, this.east, this.north].join(',');
  }

  equals(bounds: LatLngBounds, maxMargin: number): boolean {
    return (
      this._southWest.equals(bounds._southWest, maxMargin) &&
      this._northEast.equals(bounds._northEast, maxMargin)
    );
  }

  isValid(): boolean {
    return this._southWest != null && this._northEast != null;
  }
}
