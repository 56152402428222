import { PwaLocale } from '../locales/pwa-locales';
import { Domain } from './domain';

/**
 * Important: the main host for a locale is the first one in the array! This host will be used, for example, for sitemaps.
 *
 * "fo-BA": ["foo123", "baz234"]
 *
 * -> "foo123" is the main host
 */
export const pwaHostDomainsForLocale: Record<PwaLocale, Domain[]> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  'bg-BG': [{ subdomain: 'www', host: 'vremeiradar.bg' }],
  'bs-BA': [{ subdomain: 'www', host: 'vrijemeradar.ba' }],
  'cs-CZ': [{ subdomain: 'www', host: 'pocasiaradar.cz' }],
  'da-DK': [{ subdomain: 'www', host: 'vejrogradar.dk' }],
  'de-DE': [{ subdomain: 'www', host: 'wetteronline.de' }],
  'de-AT': [{ subdomain: 'www', host: 'wetteronline.at' }],
  'de-CH': [{ subdomain: 'www', host: 'wetteronline.ch' }],
  'el-GR': [{ subdomain: 'www', host: 'kairosradar.gr' }],
  'en-GB': [
    { subdomain: 'www', host: 'weatherandradar.co.uk' },
    { subdomain: 'pwa', host: 'wo-cloud.com' },
  ],
  'en-IE': [{ subdomain: 'www', host: 'weatherandradar.ie' }],
  'en-IN': [{ subdomain: 'www', host: 'weatherandradar.in' }],
  'en-US': [{ subdomain: 'www', host: 'weatherandradar.com' }],
  'es-AR': [{ subdomain: 'www', host: 'tiempoyradar.com.ar' }],
  'es-ES': [{ subdomain: 'www', host: 'tiempoyradar.es' }],
  'es-US': [
    { subdomain: 'es', host: 'weatherandradar.com' },
    { subdomain: 'www', host: 'tiempoyradar.com' },
  ],
  'fr-BE': [{ subdomain: 'www', host: 'meteoetradar.be' }],
  'fr-CH': [{ subdomain: 'www', host: 'meteoetradar.ch' }],
  'fr-FR': [
    { subdomain: 'www', host: 'meteoetradar.com' },
    { subdomain: 'www', host: 'meteoetradar.fr' },
  ],
  'hr-HR': [{ subdomain: 'www', host: 'vrijemeradar.hr' }],
  'hu-HU': [{ subdomain: 'www', host: 'idojarasesradar.hu' }],
  'it-IT': [{ subdomain: 'www', host: 'meteoeradar.it' }],
  'lt-LT': [{ subdomain: 'www', host: 'orasonline.lt' }],
  'lv-LV': [{ subdomain: 'www', host: 'meteounradars.lv' }],
  'mk-MK': [{ subdomain: 'www', host: 'vremetoiradar.com' }],
  'nl-BE': [{ subdomain: 'www', host: 'weerenradar.be' }],
  'nl-NL': [{ subdomain: 'www', host: 'weerenradar.nl' }],
  'pl-PL': [{ subdomain: 'www', host: 'pogodairadar.pl' }],
  'pt-BR': [{ subdomain: 'www', host: 'climaeradar.com.br' }],
  'pt-PT': [{ subdomain: 'www', host: 'tempoeradar.pt' }],
  'ro-RO': [{ subdomain: 'www', host: 'meteoradar.ro' }],
  'ru-UA': [{ subdomain: 'www', host: 'pogodairadar.com' }],
  'sk-SK': [{ subdomain: 'www', host: 'pocasieradar.sk' }],
  'sl-SI': [{ subdomain: 'www', host: 'vremeradar.si' }],
  'sr-Latn-RS': [{ subdomain: 'www', host: 'vremeradar.rs' }],
  'sr-Latn-ME': [{ subdomain: 'www', host: 'vrijemeradar.me' }],
  'tr-TR': [{ subdomain: 'www', host: 'havadurumuveradar.com' }],
  'uk-UA': [{ subdomain: 'www', host: 'pogodairadar.com.ua' }],
  /* eslint-enable @typescript-eslint/naming-convention */
};
