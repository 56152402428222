type TranslationsPromise = Promise<{ default: { [key: string]: string } }>;

export function loadLanguage(lang: string): Promise<{ [key: string]: string }> {
  return (
    import(
      /* webpackChunkName: "translations.faq." */ './translations/' + lang
    ) as TranslationsPromise
  )
    .catch(
      () =>
        import(
          /* webpackChunkName: "translations.faq." */ './translations/' +
            lang.split('-')[0]
        ) as TranslationsPromise,
    )
    .then((m) => m.default);
}
