import { LatLng } from '@wetteronline/geo';

import { pwaHostDomainsForLocale } from '../host-domain-mapping';
import { allLocales, AnyLocale } from '../locales';
import { OtherLocale } from '../locales/other-locales';
import { PwaLocale, pwaLocales } from '../locales/pwa-locales';
import { localizedUrls } from '../localized-urls';
import { ConstantRoute, RoutePathKey } from '../route-path-key.enum';
import { BaseUrlBuilder } from './base-url-builder';
import { Coords } from './internal-interfaces';
import {
  ArticlePageDescription,
  AuthorsPageDescription,
  MapIdPageDescription,
  PageDescription,
  PlacemarkPageDescription,
  ProductMarketingRootPageDescription,
  PurePageDescription,
  RadarLayer,
  RadarPageDescription,
  TeaserPageDescription,
} from './page-descriptions';
import { PwaUrl } from './pwa-url';
import { Url } from './url';
import {
  MapIdRecipe,
  mapIdRecipes,
  PlacemarkRecipe,
  placemarkRecipes,
  UrlRecipe,
} from './url-recipe-types';

type PathKeyMappingValue =
  | { pathKeys: RoutePathKey[] }
  | { constantRoute: ConstantRoute };

type PathKeyMapping = {
  [recipe in UrlRecipe]: PathKeyMappingValue;
};

const testLocales = ['st-TE', '2e-e2', 'st-ee'] as const;
type TestLocale = (typeof testLocales)[number];

interface RecipeMatch<T extends UrlRecipe> {
  matched: T;
  common: string[];
  rest: string[];
  queryParams: PwaUrl['queryParams'];
}

interface LocalizedData {
  hostname: string;
  subdomain: string;
  translatedRoutes: { [urlKey in RoutePathKey]: string };
}

export class PwaUrlBuilder extends BaseUrlBuilder {
  /**
   * Returns an UrlBuilder that is fully capable of building localizable routes
   * and understands any possible page descriptions
   * @param locale
   * @returns
   */
  static fromLocale(locale: string): PwaUrlBuilder {
    const matchedPwaLocale = this.findMatchingLocale(locale, pwaLocales);

    if (!matchedPwaLocale) {
      if (this.findMatchingLocale(locale, ['st-TE', '2e-e2', 'st-ee'])) {
        // Exceptions for testing purposes)
        return new PwaUrlBuilder('de-DE');
      }
      if (this.findMatchingLocale(locale, allLocales)) {
        // If locale is correct but not a PWA locale (this is true for test locales or locales we can provide translations for),
        // we don't want to throw an error but instead return a default set of urls. For these locales, we don't need a translated
        // URL anyway, but only make sure to return a valid PwaUrlbuilder object.
        return new PwaUrlBuilder('en-GB');
      } else {
        throw new Error('Invalid locale given to PwaUrlBuilder: ' + locale);
      }
    }

    if (matchedPwaLocale) {
      return new PwaUrlBuilder(matchedPwaLocale);
    } else {
      throw new Error(
        `This should never happen due to previous checks. ${locale} should have been cased correctly and
        translated URLs should have been found. Cased locale is ${matchedPwaLocale}`,
      );
    }
  }

  /**
   * Returns a "light" version of the builder, which is only capable of
   * returning constant routes that are not localizable. Useful in situations,
   * where the locale is not required or not available and only constant routes
   * are needed
   */
  static constantOnly(): PwaUrlBuilder {
    return new PwaUrlBuilder();
  }

  /**
   * Returns a match for the given locale from the given source, case
   * insensitive and alphabet-insensitive. For example, if given "sr-RS" as
   * locale, it would match to "sr-Latn-RS". It would also match "sr-RS" if
   * "sr-rs" or "sr-latn-rs" was given
   * @param locale
   * @param source
   * @returns
   */
  private static findMatchingLocale<
    T extends PwaLocale | AnyLocale | OtherLocale | TestLocale,
  >(locale: string, source: readonly T[]): T | undefined {
    const searchMapping: Record<string, T> = {};

    source.forEach((sourceLocale) => {
      // Step 1: Map every locale as lower case (key) to its original value
      searchMapping[sourceLocale.toLowerCase()] = sourceLocale;

      // Step 2: Extend the mapping by removing the middle part of any
      // 3-part-locale. For example sr-Latn-RS should not only be matched by
      // itself, but also by sr-RS
      const localeParts = sourceLocale.split('-');
      if (localeParts.length === 3) {
        const keyWithoutAlphabet =
          `${localeParts.shift()}-${localeParts.pop()}`.toLowerCase();
        searchMapping[keyWithoutAlphabet] = sourceLocale;
      }
    });

    return searchMapping[locale.toLowerCase()];
  }

  private localizedData?: LocalizedData;

  private constructor(private locale?: PwaLocale) {
    super();
  }

  private mapRecipeToUrlSegmentsOrKeys(recipe: UrlRecipe): PathKeyMappingValue {
    const pathKeysMapping: PathKeyMapping =
      // prettier-ignore
      {
      /* eslint-disable @typescript-eslint/naming-convention */
      airQuality:              { pathKeys: [RoutePathKey.airQuality] },
      editorialRoot:           { pathKeys: [RoutePathKey.weatherNewsRoot] },
      article:                 { pathKeys: [RoutePathKey.weatherNewsRoot] },
      productMarketingRoot:    { pathKeys: [RoutePathKey.productMarketingRoot] },
      productMarketingArticle: { pathKeys: [RoutePathKey.productMarketingRoot] },
      faq:                     { pathKeys: [RoutePathKey.faq] },
      imprint:                 { pathKeys: [RoutePathKey.imprint] },
      pollen:                  { pathKeys: [RoutePathKey.pollen] },
      privacy:                 { pathKeys: [RoutePathKey.privacy] },
      radar:                   { pathKeys: [RoutePathKey.weatherRadar] },
      ski:                     { pathKeys: [RoutePathKey.ski] },
      stream:                  { pathKeys: [RoutePathKey.weatherNewsRoot, RoutePathKey.ticker] },
      teaser:                  { pathKeys: [RoutePathKey.weatherNewsRoot, RoutePathKey.teaser] },
      authors:                 { pathKeys: [RoutePathKey.weatherNewsRoot, RoutePathKey.authors] },
      trend:                   { pathKeys: [RoutePathKey.trend] },
      uvIndex:                 { pathKeys: [RoutePathKey.uvIndex] },
      warningMap:              { pathKeys: [RoutePathKey.warningMap] },
      weather:                 { pathKeys: [RoutePathKey.weather] },
      weatherSymbolMap:        { pathKeys: [RoutePathKey.weatherSymbolMap] },
      weatherWidget:           { pathKeys: [RoutePathKey.weatherWidget] },
      apps:                    { constantRoute: ConstantRoute.apps },
      debug:                   { constantRoute: ConstantRoute.debug },
      editorialError:          { constantRoute: ConstantRoute.editorialError },
      error:                   { constantRoute: ConstantRoute.error },
      home:                    { constantRoute: ConstantRoute.home },
      notFound:                { constantRoute: ConstantRoute.notFound },
      /* eslint-enable @typescript-eslint/naming-convention */
    };

    return pathKeysMapping[recipe];
  }

  get hostname(): string {
    return this.ensureLocalizability().hostname;
  }

  get subDomain(): string {
    return this.ensureLocalizability().subdomain;
  }

  basePath(recipe: UrlRecipe): Url {
    const pathOrPathKey = this.mapRecipeToUrlSegmentsOrKeys(recipe);
    const segments: string[] = [];
    if ('pathKeys' in pathOrPathKey) {
      segments.push(
        ...pathOrPathKey.pathKeys.map(
          (key) => this.ensureLocalizability().translatedRoutes[key],
        ),
      );
    } else {
      segments.push(pathOrPathKey.constantRoute);
    }

    if (segments[0].length > 0) {
      // Only insert root element (which will lead to route starting with slash)
      // if the first element has content. Otherwise we'd have two consecutive
      // empty strings in our path later, which would lead to bugs, such as the
      // home screen being not linkable
      segments.unshift('');
    }

    return new PwaUrl({
      segments,
    });
  }

  doesRecognize(pathSegment: string): boolean {
    const unslashedSegment = this.unslash(pathSegment);
    return Object.values(this.ensureLocalizability().translatedRoutes).some(
      (route) => route === unslashedSegment,
    );
  }

  translate(foreignSegment: string): string | undefined {
    const unslashedForeignSegment = this.unslash(foreignSegment);
    for (const foreignUrls of Object.values(localizedUrls)) {
      const [foundUrlKey] = Object.entries(foreignUrls).find(
        ([, urlSegment]) => urlSegment === unslashedForeignSegment,
      ) ?? [undefined];

      if (foundUrlKey) {
        return this.ensureLocalizability().translatedRoutes[
          foundUrlKey as RoutePathKey
        ];
      }
    }

    return undefined;
  }

  /** Remove leading/trailing slashes */
  private unslash(s: string): string {
    return s.replace(/^\/+/g, '').replace(/\/+$/g, '');
  }

  private ensureLocalizability(): LocalizedData {
    if (this.localizedData != null) {
      return this.localizedData;
    }

    if (!this.locale) {
      throw new Error(
        'You may not query localized data from the light builder. Please use PwaUrlBuilder.fromLocale to get a builder that is capable of generating localized data.',
      );
    }

    return (this.localizedData = {
      hostname: pwaHostDomainsForLocale[this.locale][0].host,
      subdomain: pwaHostDomainsForLocale[this.locale][0].subdomain,
      translatedRoutes: localizedUrls[this.locale],
    });
  }

  protected buildPlacemarkBased(input: PlacemarkPageDescription): Url {
    return new PwaUrl({
      segments: [
        ...this.basePath(input.recipe).pathSegments,
        input.urlPath,
        input.geoObjectKey,
      ],
    });
  }

  protected buildMapIdBased(input: MapIdPageDescription): Url {
    return new PwaUrl({
      segments: [...this.basePath(input.recipe).pathSegments, input.mapId],
    });
  }

  protected buildArticle(input: ArticlePageDescription): Url {
    return new PwaUrl({
      segments: [...this.basePath(input.recipe).pathSegments, input.postId],
    });
  }

  protected buildTeaser(input: TeaserPageDescription): Url {
    return new PwaUrl({
      segments: [
        ...this.basePath(input.recipe).pathSegments,
        input.category !== 'Topics' ? input.category?.toLowerCase() : '',
        input.page,
      ],
    });
  }

  protected override buildProductMarketingRoot(
    input: ProductMarketingRootPageDescription,
  ): Url {
    const segments = [...this.basePath(input.recipe).pathSegments];
    if (input.page != null) {
      segments.push(input.page);
    }
    return new PwaUrl({ segments });
  }

  protected buildAuthors(input: AuthorsPageDescription): Url {
    if (input.fragment) {
      return new PwaUrl({
        segments: [
          ...this.basePath(input.recipe).pathSegments,
          `#${input.fragment}`,
        ],
      });
    }
    return new PwaUrl({
      segments: [...this.basePath(input.recipe).pathSegments],
    });
  }

  protected buildRadar(input: RadarPageDescription): Url {
    const queryParams: Record<string, string> = {};

    if (input.zoom != null) {
      queryParams['zoom'] = `${input.zoom}`;
    }

    if (input.layer) {
      queryParams['layer'] = ((l: RadarLayer) => {
        switch (l) {
          // Default to shortcuts for query params in radar url
          case 'WetterRadar':
            return 'wr';
          case 'RegenRadar':
            return 'rr';
          case 'Temperature':
            return 'tr';
          case 'Gust':
            return 'gr';
          case 'Lightning':
            return 'lr';
          default:
            return l;
        }
      })(input.layer);
    }

    const coordsToString = (coords: Coords): string => {
      if ('lat' in coords) {
        return [coords.lat.toString(), coords.lng.toString()].join(',');
      }
      if ('latitude' in coords) {
        return [coords.latitude.toString(), coords.longitude.toString()].join(
          ',',
        );
      }
      throw new Error('should never happen');
    };

    if (input.centerCoords) {
      queryParams['center'] = coordsToString(input.centerCoords);
    }

    if (input.placemarkCoords) {
      queryParams['placemark'] = coordsToString(input.placemarkCoords);
    }

    for (const key of ['bounds', 'period', 'loop', 'timeStep', 'tz'] as const) {
      const val = input[key];
      if (val) {
        queryParams[key] = val;
      }
    }

    return new PwaUrl({
      segments: [
        ...this.basePath(input.recipe).pathSegments,
        input.urlPath,
        input.geoObjectKey,
      ],
      queryParams,
      queryParamSorter: (a, b) => {
        if (a === b) {
          return 0;
        }
        // Add `center` as the first item to ensure bugfix #866
        if (a === 'center') {
          return -1;
        }
        if (b === 'center') {
          return 1;
        }
        return a < b ? -1 : 1;
      },
    });
  }

  protected buildPure(input: PurePageDescription): Url {
    return new PwaUrl({
      segments: [...this.basePath(input.recipe).pathSegments],
    });
  }

  parse(url: Url): PageDescription | null {
    const placemarkRecipe = this.oneRecipeMatches(url, placemarkRecipes);
    if (placemarkRecipe) {
      return this.parsePlacemarkBased(
        placemarkRecipe as RecipeMatch<PlacemarkRecipe>,
      );
    }

    const mapIdMatch = this.oneRecipeMatches(url, mapIdRecipes);
    if (mapIdMatch) {
      return this.parseMapIdBased(mapIdMatch as RecipeMatch<MapIdRecipe>);
    }

    const authorsMatch = this.oneRecipeMatches(url, ['authors']);
    if (authorsMatch) {
      return this.parseAuthorsBased(authorsMatch as RecipeMatch<'authors'>);
    }

    const teaserMatch = this.oneRecipeMatches(url, ['teaser']);
    if (teaserMatch) {
      return this.parseTeaserBased(teaserMatch as RecipeMatch<'teaser'>);
    }

    if (this.oneRecipeMatches(url, ['stream'])) {
      return { recipe: 'stream' };
    }

    if (this.oneRecipeMatches(url, ['privacy'])) {
      return { recipe: 'privacy' };
    }

    const articleMatch = this.oneRecipeMatches(url, ['article']);
    if (articleMatch) {
      return this.parseArticleBased(articleMatch as RecipeMatch<'article'>);
    }

    const trendMatch = this.oneRecipeMatches(url, ['trend']);
    if (trendMatch) {
      return this.parseTrendBased(trendMatch as RecipeMatch<'trend'>);
    }

    const radarMatch = this.oneRecipeMatches(url, ['radar']);
    if (radarMatch) {
      return this.parseRadarBased(radarMatch as RecipeMatch<'radar'>);
    }

    return null;
  }

  private oneRecipeMatches(
    url: Url,
    recipes: readonly UrlRecipe[],
  ): RecipeMatch<UrlRecipe> | undefined {
    for (const recipe of recipes) {
      const basePath = this.basePath(recipe);
      if (url.pathStartsWith(basePath)) {
        return {
          matched: recipe,
          common: basePath.pathSegments,
          rest: url.pathSegments.slice(basePath.pathSegments.length),
          queryParams: url.queryParams,
        };
      }
    }
    return;
  }

  private parsePlacemarkBased(
    match: RecipeMatch<PlacemarkRecipe>,
  ): PageDescription | null {
    return {
      recipe: match.matched,
      urlPath: match.rest[0],
      geoObjectKey: match.rest[1],
    };
  }

  private parseMapIdBased(match: RecipeMatch<MapIdRecipe>): PageDescription {
    return {
      recipe: match.matched,
      mapId: match.rest[0],
    };
  }

  private parseAuthorsBased(match: RecipeMatch<'authors'>): PageDescription {
    return {
      recipe: match.matched,
    };
  }

  private parseArticleBased(match: RecipeMatch<'article'>): PageDescription {
    return {
      recipe: match.matched,
      postId: match.rest[0],
    };
  }

  private parseTrendBased(match: RecipeMatch<'trend'>): PageDescription {
    return {
      recipe: match.matched,
    };
  }

  private parseTeaserBased(match: RecipeMatch<'teaser'>): PageDescription {
    return {
      recipe: match.matched,
      page: match.rest[0],
    };
  }

  private parseRadarBased(match: RecipeMatch<'radar'>): PageDescription {
    let centerCoords: LatLng | undefined;
    if (match.queryParams['center']) {
      centerCoords = this.parseCoords(match.queryParams['center']);
    }

    let placemarkCoords: LatLng | undefined;
    if (match.queryParams['placemark']) {
      placemarkCoords = this.parseCoords(match.queryParams['placemark']);
    }

    let zoom: number | undefined;
    if (match.queryParams['zoom']) {
      const parsed = parseFloat(match.queryParams['zoom']);
      if (!isNaN(parsed)) {
        zoom = parsed;
      }
    }

    return {
      recipe: match.matched,
      urlPath: match.rest[0],
      geoObjectKey: match.rest[1],
      bounds: match.queryParams['bounds'],
      layer: match.queryParams['layer'] as RadarPageDescription['layer'],
      centerCoords,
      placemarkCoords,
      zoom,
      loop: match.queryParams['loop'] === 'true' ? 'true' : undefined,
      period: match.queryParams['period'] as RadarPageDescription['period'],
      timeStep: match.queryParams['timeStep'],
    };
  }

  private parseCoords(s: string): LatLng | undefined {
    const split = s.split(',');
    return LatLng.fromString(split[0], split[1]) ?? undefined;
  }
}
