/**
 * Klasse für einen geometrischen Punkt
 */
export class Point {
  z!: number;

  /**
   * @param {number} x x-Koordinate, default: 0
   * @param {number} y y-Koordinate, default: x
   * @param {number | boolean} zRound z-Koordinate oder <b>true</b>, wenn die x- und y-Koordinaten gerundet werden sollen
   */
  constructor(
    public x = 0,
    public y: number = x,
    zRound?: number | boolean,
  ) {
    if (typeof zRound === 'number') {
      this.z = zRound;
    } else if (zRound === true) {
      this._round();
    }
  }

  /**
   * Kopiert diesen Punkt und addiert danach den übergebenen Punkt auf diesen
   * @param {Point} p
   * @returns {Point}
   */
  add(p: Point): Point {
    return this.clone()._add(p);
  }

  /**
   * Addiert den übergebenen Punkt auf diesen Punkt
   * @param {Point} p
   * @returns {Point}
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _add(p: Point): Point {
    this.x += p.x;
    this.y += p.y;
    return this;
  }

  /**
   * Kopiert diesen Punkt und subtrahiert danach den übergebenen Punkt von diesem
   * @param {Point} p
   * @returns {Point}
   */
  subtract(p: Point): Point {
    return this.clone()._subtract(p);
  }

  /**
   * Subtrahiert den übergebenen Punkt von diesem
   * @param {Point} p
   * @returns {Point}
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _subtract(p: Point): Point {
    this.x -= p.x;
    this.y -= p.y;
    return this;
  }

  /**
   * Kopiert diesen Punkt und multipliziert die x- und y-Koordinaten mit dem übergebenen Faktor
   * @param {number} n
   * @returns {Point}
   */
  multiplyBy(n: number): Point {
    return this.clone()._multiplyBy(n);
  }

  /**
   * Multipliziert die x- und y-Koordinaten dieses Punktes mit dem übergebenen Faktor
   * @param {number} n
   * @returns {Point}
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _multiplyBy(n: number): Point {
    this.x *= n;
    this.y *= n;
    return this;
  }

  /**
   * Kopiert diesen Punkt und dividiert die x- und y-Koordinaten durch den übergebenen Divisor
   * @param {number} n
   * @returns {Point}
   */
  divideBy(n: number): Point {
    return this.clone()._divideBy(n);
  }

  /**
   * Dividiert die x- und y-Koordinaten dieses Punktes durch den übergebenen Divisor
   * @param {number} n
   * @returns {Point}
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _divideBy(n: number): Point {
    this.x /= n;
    this.y /= n;
    return this;
  }

  /**
   * Kopiert diesen Punkt und multipliziert die Koordinaten mit den jeweiligen Koordinaten des übergebenen Punktes
   * @param {Point} p
   * @returns {Point}
   */
  scaleBy(p: Point): Point {
    return this.clone()._scaleBy(p);
  }

  /**
   * Multipliziert die Koordinaten dieses Punktes mit den jeweiligen Koordinaten des übergebenen Punktes
   * @param {Point} p
   * @returns {Point}
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _scaleBy(p: Point): Point {
    this.x *= p.x;
    this.y *= p.y;
    return this;
  }

  /**
   * Kopiert diesen Punkt und dividiert die Koordinaten durch die jeweiligen Koordinaten des übergebenen Punktes
   * @param {Point} p
   * @returns {Point}
   */
  unscaleBy(p: Point): Point {
    return this.clone()._unscaleBy(p);
  }

  /**
   * Dividiert die Koordinaten dieses Punktes mit den jeweiligen Koordinaten des übergebenen Punktes
   * @param {Point} p
   * @returns {Point}
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _unscaleBy(p: Point): Point {
    this.x /= p.x;
    this.y /= p.y;
    return this;
  }

  /**
   * Kopiert diesen Punkt und rundet die Koordinaten auf ganze Zahlen
   * @returns {Point}
   */
  round(precision = 1): Point {
    return this.clone()._round(precision);
  }

  /**
   * Rundet die Koordinaten dieses Punktes auf ganze Zahlen
   * @returns {Point}
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _round(precision = 1): Point {
    this.x = Math.round(this.x / precision) * precision;
    this.y = Math.round(this.y / precision) * precision;
    return this;
  }

  /**
   * Kopiert diesen Punkt und rundet die Koordinaten ab
   * @returns {Point}
   */
  floor(): Point {
    return this.clone()._floor();
  }

  /**
   * Rundet die Koordinaten dieses Punktes ab
   * @returns {Point}
   * @private
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _floor(): Point {
    this.x = Math.floor(this.x);
    this.y = Math.floor(this.y);
    return this;
  }

  /**
   * Kopiert diesen Punkt und rundet die Koordinaten auf
   * @returns {Point}
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ceil(): Point {
    return this.clone()._ceil();
  }

  /**
   * Rundet die Koordinaten dieses Punktes auf
   * @returns {Point}
   * @private
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _ceil(): Point {
    this.x = Math.ceil(this.x);
    this.y = Math.ceil(this.y);
    return this;
  }

  /**
   * Gibt die Distanz zu dem übergebenen Punkt zurück
   * @param {Point} p
   * @returns {number}
   */
  distanceTo(p: Point): number {
    const dX = p.x - this.x,
      dY = p.y - this.y;
    return Math.sqrt(dX * dX + dY * dY);
  }

  /**
   * Gibt die quadrierte Distanz zu dem übergebenen Punkt zurück
   * @param {Point} p
   * @returns {number}
   */
  distanceSquaredTo(p: Point): number {
    const dX = p.x - this.x,
      dY = p.y - this.y;
    return dX * dX + dY * dY;
  }

  /**
   * Kopiert diesen Punkt
   * @returns {Point}
   */
  clone(): Point {
    return new Point(this.x, this.y, this.z);
  }

  /**
   * Gibt eine String-Repräsentation dieses Punktes zurück
   * @returns {string}
   */
  toString(): string {
    return `(${this.x},${this.y})`;
  }

  /**
   * Vergleicht zwei Punkte
   * @Param (Point) p
   * @returns (boolean)
   */
  equals(p: Point): boolean {
    return this.x === p.x && this.y === p.y && this.z === p.z;
  }
}
