import { LatLng } from '../LatLng';
import { NumberRange } from '../Util';
import { CRS } from './CRS';
import { EARTH_R, EARTH_WRAP_LNG, earthDistance } from './earth';

export abstract class CRSEarth extends CRS {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static R = EARTH_R;
  override wrapLng: NumberRange = EARTH_WRAP_LNG;

  override distance(latlng1: LatLng, latlng2: LatLng): number {
    return earthDistance(latlng1, latlng2);
  }
}
