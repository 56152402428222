import { LatLng } from './LatLng';

export class LatLngWithAccuracy extends LatLng {
  constructor(
    lat: number,
    lng: number,
    alt: number | undefined,
    public accuracy: number,
    public altitudeAccuracy?: number,
  ) {
    super(lat, lng, alt);
  }
}
