/* eslint-disable @typescript-eslint/naming-convention */
import { PwaLocale } from './locales/pwa-locales';
import { RoutePathKey } from './route-path-key.enum';

type LocalizedUrlsSet = {
  [urlKey in RoutePathKey]: string;
};

type LocalizedUrls = Record<PwaLocale, LocalizedUrlsSet>;

const deRoutes: LocalizedUrlsSet = {
  url_imprint: 'impressum',
  url_pollen: 'pollen',
  url_privacy: 'datenschutz',
  url_ticker: 'aktuell',
  url_weather: 'wetter',
  url_weather_map: 'wetterradar',
  url_weather_symbol_map: 'wettervorhersage',
  url_warning_map: 'wetterwarnungen',
  url_air_quality: 'luftqualitaet',
  url_ski_info: 'ski-info',
  url_teaser: 'editors-pick',
  url_weather_news_root: 'wetterticker',
  url_product_marketing_root: 'app-entdecken',
  url_uv_index: 'uv-index',
  url_weather_widget: 'wetter-widget',
  url_authors: 'autoren',
  url_weather_reports: 'wetterbericht',
  url_faq: 'faq',
};

export const localizedUrls: LocalizedUrls = {
  'bg-BG': {
    url_imprint: 'kontakti',
    url_pollen: 'poleni',
    url_privacy: 'zashtita-na-danni',
    url_ticker: 'nakratko-za-vremeto',
    url_weather: 'vreme',
    url_weather_map: 'meteorologichen-radar',
    url_weather_symbol_map: 'prognozna-karta',
    url_warning_map: 'preduprejdeniya-za-priblijavashti-buri',
    url_air_quality: 'kachestvo-na-vyzduha',
    url_ski_info: 'ski-info',
    url_teaser: 'editors-pick',
    url_weather_news_root: 'meteo-novini',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-indeks',
    url_weather_widget: 'uidjet-za-vremeto',
    url_authors: 'redaktori',
    url_weather_reports: 'meteorologichni-tendencii',
    url_faq: 'chesto-zadavani-vaprosi',
  },
  'bs-BA': {
    url_imprint: 'otisak',
    url_pollen: 'polen',
    url_privacy: 'privatnost',
    url_ticker: 'vijesti',
    url_weather: 'vrijeme',
    url_weather_map: 'vremenski-radar',
    url_weather_symbol_map: 'prognoza-vremena',
    url_warning_map: 'meteo-upozorenja',
    url_air_quality: 'kvalitet-zraka',
    url_ski_info: 'ski-info',
    url_teaser: 'izbor-urednika',
    url_weather_news_root: 'vijesti-o-vremenu',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-indeks',
    url_weather_widget: 'vrijeme-vidzet',
    url_authors: 'redakcija',
    url_weather_reports: 'vremenski-trend',
    url_faq: 'ucestala-pitanja',
  },
  'cs-CZ': {
    url_imprint: 'impressum',
    url_pollen: 'pylove-zpravodajstvi',
    url_privacy: 'ochrana-osobnich-udaju',
    url_ticker: 'aktualne',
    url_weather: 'pocasi',
    url_weather_map: 'meteoradar',
    url_weather_symbol_map: 'predpoved-pocasi',
    url_warning_map: 'meteorologicke-vystrahy',
    url_air_quality: 'kvalita-ovzdusi',
    url_ski_info: 'snehove-zpravodajstvi',
    url_teaser: 'redakcni-tipy',
    url_weather_news_root: 'zpravy-o-pocasi',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-index',
    url_weather_widget: 'widget-pocasi',
    url_authors: 'autori',
    url_weather_reports: 'vyhled-pocasi',
    url_faq: 'caste-dotazy',
  },
  'da-DK': {
    url_imprint: 'impressum',
    url_pollen: 'pollen',
    url_privacy: 'databeskyttelse',
    url_ticker: 'seneste',
    url_weather: 'vejret',
    url_weather_map: 'vejrradar',
    url_weather_symbol_map: 'vejrudsigt',
    url_warning_map: 'vejrvarsler',
    url_air_quality: 'luftkvalitet',
    url_ski_info: 'ski-info',
    url_teaser: 'anbefalede-artikler',
    url_weather_news_root: 'vejrnyheder',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-indeks',
    url_weather_widget: 'vejr-widget',
    url_authors: 'redaktion',
    url_weather_reports: 'vejrtrend',
    url_faq: 'faq',
  },
  'de-DE': deRoutes,
  'de-AT': deRoutes,
  'de-CH': deRoutes,
  'el-GR': {
    url_imprint: 'stichia-ekdosis',
    url_pollen: 'gyri',
    url_privacy: 'politiki-aporritou',
    url_ticker: 'eidiseis-kairou',
    url_weather: 'kairos',
    url_weather_map: 'rantar-kairou',
    url_weather_symbol_map: 'prognosi-kairou',
    url_warning_map: 'proeidopoihsh-kakokairias',
    url_air_quality: 'poiotita-aera',
    url_ski_info: 'plirofories-ski',
    url_teaser: 'editors-pick',
    url_weather_news_root: 'kairikes-eidiseis',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'deiktis-uv',
    url_weather_widget: 'widget-kairou',
    url_authors: 'syngrafeis',
    url_weather_reports: 'prognosi-kairou',
    url_faq: 'faq',
  },
  'en-GB': {
    url_imprint: 'imprint',
    url_pollen: 'pollen',
    url_privacy: 'privacy',
    url_ticker: 'latest',
    url_weather: 'weather',
    url_weather_map: 'weather-map',
    url_weather_symbol_map: 'weather-forecast',
    url_warning_map: 'weather-warnings',
    url_air_quality: 'air-quality',
    url_ski_info: 'ski-info',
    url_teaser: 'editors-pick',
    url_weather_news_root: 'weather-news',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-index',
    url_weather_widget: 'weather-widget',
    url_authors: 'authors',
    url_weather_reports: 'weather-report',
    url_faq: 'faq',
  },
  'en-IE': {
    url_imprint: 'imprint',
    url_pollen: 'pollen',
    url_privacy: 'privacy',
    url_ticker: 'latest',
    url_weather: 'weather',
    url_weather_map: 'weather-map',
    url_weather_symbol_map: 'weather-forecast',
    url_warning_map: 'weather-warnings',
    url_air_quality: 'air-quality',
    url_ski_info: 'ski-info',
    url_teaser: 'editors-pick',
    url_weather_news_root: 'weather-news',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-index',
    url_weather_widget: 'weather-widget',
    url_authors: 'authors',
    url_weather_reports: 'weather-report',
    url_faq: 'faq',
  },
  'en-IN': {
    url_imprint: 'imprint',
    url_pollen: 'pollen',
    url_privacy: 'privacy',
    url_ticker: 'latest',
    url_weather: 'weather',
    url_weather_map: 'weather-map',
    url_weather_symbol_map: 'weather-forecast',
    url_warning_map: 'weather-warnings',
    url_air_quality: 'air-quality',
    url_ski_info: 'ski-info',
    url_teaser: 'editors-pick',
    url_weather_news_root: 'weather-news',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-index',
    url_weather_widget: 'weather-widget',
    url_authors: 'authors',
    url_weather_reports: 'weather-report',
    url_faq: 'faq',
  },
  'en-US': {
    url_imprint: 'imprint',
    url_pollen: 'pollen',
    url_privacy: 'privacy',
    url_ticker: 'latest',
    url_weather: 'weather',
    url_weather_map: 'weather-map',
    url_weather_symbol_map: 'weather-forecast',
    url_warning_map: 'weather-alerts',
    url_air_quality: 'air-quality',
    url_ski_info: 'ski-info',
    url_teaser: 'editors-pick',
    url_weather_news_root: 'weather-news',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-index',
    url_weather_widget: 'weather-widget',
    url_authors: 'authors',
    url_weather_reports: 'weather-report',
    url_faq: 'faq',
  },
  'es-AR': {
    url_imprint: 'aviso-legal',
    url_pollen: 'polen',
    url_privacy: 'proteccion-de-datos',
    url_ticker: 'ultima-hora',
    url_weather: 'tiempo',
    url_weather_map: 'radar-meteorologico',
    url_weather_symbol_map: 'pronostico-del-tiempo',
    url_warning_map: 'alertas-por-tiempo-extremo',
    url_air_quality: 'calidad-del-aire',
    url_ski_info: 'parte-de-nieve',
    url_teaser: 'recomendado',
    url_weather_news_root: 'noticias-del-tiempo',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'indice-uv',
    url_weather_widget: 'widget-del-tiempo',
    url_authors: 'redactores',
    url_weather_reports: 'tendencia-meteorologica',
    url_faq: 'preguntas-frecuentes',
  },
  'es-ES': {
    url_imprint: 'aviso-legal',
    url_pollen: 'polen',
    url_privacy: 'privacidad',
    url_ticker: 'ultima-hora',
    url_weather: 'tiempo',
    url_weather_map: 'radar-meteorologico',
    url_weather_symbol_map: 'prevision-tiempo',
    url_warning_map: 'alertas-por-tiempo-extremo',
    url_air_quality: 'calidad-del-aire',
    url_ski_info: 'parte-de-nieve',
    url_teaser: 'recomendado',
    url_weather_news_root: 'noticias-del-tiempo',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'indice-uv',
    url_weather_widget: 'widget-del-tiempo',
    url_authors: 'redactores',
    url_weather_reports: 'tendencia-meteorologica',
    url_faq: 'preguntas-frecuentes',
  },
  'es-US': {
    url_imprint: 'aviso-legal',
    url_pollen: 'polen',
    url_privacy: 'proteccion-de-datos',
    url_ticker: 'ultima-hora',
    url_weather: 'tiempo',
    url_weather_map: 'radar-meteorologico',
    url_weather_symbol_map: 'prevision-del-tiempo',
    url_warning_map: 'alertas-por-tiempo-extremo',
    url_air_quality: 'calidad-del-aire',
    url_ski_info: 'parte-de-nieve',
    url_teaser: 'recomendado',
    url_weather_news_root: 'noticias-del-tiempo',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'indice-uv',
    url_weather_widget: 'widget-del-tiempo',
    url_authors: 'redactores',
    url_weather_reports: 'tendencia-meteorologica',
    url_faq: 'preguntas-frecuentes',
  },
  'fr-BE': {
    url_imprint: 'mentions-legales',
    url_pollen: 'pollen',
    url_privacy: 'confidentialite',
    url_ticker: 'fil-des-actualites',
    url_weather: 'meteo',
    url_weather_map: 'carte-meteo',
    url_weather_symbol_map: 'carte-des-previsions',
    url_warning_map: 'alertes-intemperies',
    url_air_quality: 'qualite-de-l-air',
    url_ski_info: 'bulletin-neige',
    url_teaser: 'selection',
    url_weather_news_root: 'actualites-meteo',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'indice-uv',
    url_weather_widget: 'widget-meteo',
    url_authors: 'redacteurs',
    url_weather_reports: 'tendance-meteo',
    url_faq: 'faq',
  },
  'fr-CH': {
    url_imprint: 'mentions-legales',
    url_pollen: 'pollen',
    url_privacy: 'confidentialite',
    url_ticker: 'fil-des-actualites',
    url_weather: 'meteo',
    url_weather_map: 'carte-meteo',
    url_weather_symbol_map: 'carte-des-previsions',
    url_warning_map: 'alertes-intemperies',
    url_air_quality: 'qualite-de-l-air',
    url_ski_info: 'bulletin-neige',
    url_teaser: 'selection',
    url_weather_news_root: 'actualites-meteo',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'indice-uv',
    url_weather_widget: 'widget-meteo',
    url_authors: 'redacteurs',
    url_weather_reports: 'tendance-meteo',
    url_faq: 'faq',
  },
  'fr-FR': {
    url_imprint: 'mentions-legales',
    url_pollen: 'pollen',
    url_privacy: 'confidentialite',
    url_ticker: 'fil-des-actualites',
    url_weather: 'meteo',
    url_weather_map: 'carte-meteo',
    url_weather_symbol_map: 'carte-des-previsions',
    url_warning_map: 'alertes-intemperies',
    url_air_quality: 'qualite-de-l-air',
    url_ski_info: 'bulletin-neige',
    url_teaser: 'selection',
    url_weather_news_root: 'actualites-meteo',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'indice-uv',
    url_weather_widget: 'widget-meteo',
    url_authors: 'redacteurs',
    url_weather_reports: 'tendance-meteo',
    url_faq: 'faq',
  },
  'hr-HR': {
    url_imprint: 'otisak',
    url_pollen: 'pelud',
    url_privacy: 'privatnost',
    url_ticker: 'novosti',
    url_weather: 'vrijeme',
    url_weather_map: 'vremenski-radar',
    url_weather_symbol_map: 'vremenska-prognoza',
    url_warning_map: 'upozorenja-o-vremenu',
    url_air_quality: 'kvaliteta-zraka',
    url_ski_info: 'ski-info',
    url_teaser: 'izbor-urednika',
    url_weather_news_root: 'vijesti-o-vremenu',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-indeks',
    url_weather_widget: 'vrijeme-vidzet',
    url_authors: 'redakcija',
    url_weather_reports: 'vremenski-trend',
    url_faq: 'ucestala-pitanja',
  },
  'hu-HU': {
    url_imprint: 'impresszum',
    url_pollen: 'pollen',
    url_privacy: 'adatvedelem',
    url_ticker: 'aktualis',
    url_weather: 'idojaras',
    url_weather_map: 'idojaras-terkep',
    url_weather_symbol_map: 'terkepes-elorejelzes',
    url_warning_map: 'idojaras-figyelmeztetes',
    url_air_quality: 'levegominoseg',
    url_ski_info: 'sipalya-informaciok',
    url_teaser: 'magazin',
    url_weather_news_root: 'idojaras-info',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-index',
    url_weather_widget: 'idojaras-widget',
    url_authors: 'szerkesztoseg',
    url_weather_reports: 'idojaras-trend',
    url_faq: 'gyik',
  },
  'it-IT': {
    url_imprint: 'note-legali',
    url_pollen: 'pollini',
    url_privacy: 'privacy',
    url_ticker: 'ultime',
    url_weather: 'pagina-meteo',
    url_weather_map: 'radar-meteo',
    url_weather_symbol_map: 'previsioni-meteo',
    url_warning_map: 'allerte-meteo',
    url_air_quality: 'qualita-dell-aria',
    url_ski_info: 'bollettino-neve',
    url_teaser: 'selezione-della-redazione',
    url_weather_news_root: 'notizie-meteo',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'indice-uv',
    url_weather_widget: 'widget-meteo',
    url_authors: 'autori',
    url_weather_reports: 'tendenza-meteo',
    url_faq: 'domande-frequenti',
  },
  'lt-LT': {
    url_imprint: 'rekvizitai',
    url_pollen: 'ziedadulkes',
    url_privacy: 'duomenu-apsauga',
    url_ticker: 'naujienos',
    url_weather: 'ororaiai',
    url_weather_map: 'oru-zemelapis',
    url_weather_symbol_map: 'oru-prognozes',
    url_warning_map: 'audru-ispejimai',
    url_air_quality: 'oro-kokybe',
    url_ski_info: 'slidinejimo-informacija',
    url_teaser: 'editors-pick',
    url_weather_news_root: 'oru-naujienos',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-indeksas',
    url_weather_widget: 'oru-valdiklis',
    url_authors: 'autoriai',
    url_weather_reports: 'oru-pranesimai',
    url_faq: 'duk',
  },
  'lv-LV': {
    url_imprint: 'kontaktinformacija',
    url_pollen: 'ziedputeksni',
    url_privacy: 'privatuma-politika',
    url_ticker: 'laikapstaklu-raditajs',
    url_weather: 'laikazinas',
    url_weather_map: 'laikapstaklu-radars',
    url_weather_symbol_map: 'laika-prognozes-karte',
    url_warning_map: 'bridinajumi-par-negaisu',
    url_air_quality: 'gaisa-kvalitate',
    url_ski_info: 'sleposanas-informacija',
    url_teaser: 'editors-pick',
    url_weather_news_root: 'laika-zinas',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-indekss',
    url_weather_widget: 'laikapstaklu-logriks',
    url_authors: 'redakcija',
    url_weather_reports: 'laikapstaklu-tendences',
    url_faq: 'faq',
  },
  'mk-MK': {
    url_imprint: 'otpecatok',
    url_pollen: 'polen',
    url_privacy: 'privatnost',
    url_ticker: 'vesti',
    url_weather: 'vremeto',
    url_weather_map: 'vremenski-radar',
    url_weather_symbol_map: 'vremenska-prognoza',
    url_warning_map: 'vremensko-predupreduvanje',
    url_air_quality: 'kvalitet-na-vazduhot',
    url_ski_info: 'ski-info',
    url_teaser: 'izbor-na-urednikot',
    url_weather_news_root: 'vesti-za-vremeto',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-indeks',
    url_weather_widget: 'vremeto-vidzet',
    url_authors: 'redakcija',
    url_weather_reports: 'vremeto-trend',
    url_faq: 'najcesta-pitanja',
  },
  'nl-BE': {
    url_imprint: 'colofon',
    url_pollen: 'pollen',
    url_privacy: 'privacy',
    url_ticker: 'tikker',
    url_weather: 'weer',
    url_weather_map: 'weerradar',
    url_weather_symbol_map: 'weersverwachting',
    url_warning_map: 'weerwaarschuwingen',
    url_air_quality: 'luchtkwaliteit',
    url_ski_info: 'ski-info',
    url_teaser: 'aanbevolen',
    url_weather_news_root: 'weer-nieuws',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-index',
    url_weather_widget: 'weerwidget',
    url_authors: 'auteurs',
    url_weather_reports: '14daagse',
    url_faq: 'faq',
  },
  'nl-NL': {
    url_imprint: 'colofon',
    url_pollen: 'pollen',
    url_privacy: 'privacy',
    url_ticker: 'tikker',
    url_weather: 'weer',
    url_weather_map: 'weerradar',
    url_weather_symbol_map: 'weersverwachting',
    url_warning_map: 'weerwaarschuwingen',
    url_air_quality: 'luchtkwaliteit',
    url_ski_info: 'ski-info',
    url_teaser: 'aanbevolen',
    url_weather_news_root: 'weer-nieuws',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-index',
    url_weather_widget: 'weerwidget',
    url_authors: 'auteurs',
    url_weather_reports: '14daagse',
    url_faq: 'faq',
  },
  'pl-PL': {
    url_imprint: 'stopka-redakcyjna',
    url_pollen: 'pylenie',
    url_privacy: 'prywatnosc',
    url_ticker: 'najnowsze',
    url_weather: 'pogoda',
    url_weather_map: 'radar-pogodowy',
    url_weather_symbol_map: 'prognoza-pogody',
    url_warning_map: 'ostrzezenia-pogodowe',
    url_air_quality: 'jakosc-powietrza',
    url_ski_info: 'informacje-dla-narciarzy',
    url_teaser: 'polecane',
    url_weather_news_root: 'wiadomosci-pogodowe',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'indeks-uv',
    url_weather_widget: 'widget-pogodowy',
    url_authors: 'redaktorzy',
    url_weather_reports: 'trend-pogodowy',
    url_faq: 'czeste-pytania',
  },
  'pt-BR': {
    url_imprint: 'informacoes-legais',
    url_pollen: 'polen',
    url_privacy: 'politica-privacidade',
    url_ticker: 'noticias',
    url_weather: 'previsao-tempo',
    url_weather_map: 'radarclima',
    url_weather_symbol_map: 'mapa-tempo',
    url_warning_map: 'alertas-meteorologicos',
    url_air_quality: 'qualidade-do-ar',
    url_ski_info: 'info-ski',
    url_teaser: 'editors-pick',
    url_weather_news_root: 'noticias',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'indice-uv',
    url_weather_widget: 'widget-do-tempo',
    url_authors: 'autores',
    url_weather_reports: 'tendencia-meteorologica',
    url_faq: 'perguntas-frequentes',
  },
  'pt-PT': {
    url_imprint: 'ficha-tecnica',
    url_pollen: 'polen',
    url_privacy: 'politica-de-privacidade',
    url_ticker: 'noticias',
    url_weather: 'tempo',
    url_weather_map: 'mapa-do-tempo',
    url_weather_symbol_map: 'previsao-do-tempo',
    url_warning_map: 'avisos-meteorologicos',
    url_air_quality: 'qualidade-do-ar',
    url_ski_info: 'info-ski',
    url_teaser: 'editors-pick',
    url_weather_news_root: 'noticias',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'indice-uv',
    url_weather_widget: 'widget-do-tempo',
    url_authors: 'autores',
    url_weather_reports: 'tendencia-meteorologica',
    url_faq: 'perguntas-frequentes',
  },
  'ro-RO': {
    url_imprint: 'informatii-legale',
    url_pollen: 'polen',
    url_privacy: 'protectia-datelor',
    url_ticker: 'cele-mai-recente',
    url_weather: 'vremea',
    url_weather_map: 'harta-meteo',
    url_weather_symbol_map: 'prognoza-meteo',
    url_warning_map: 'avertizari-meteo',
    url_air_quality: 'calitatea-aerului',
    url_ski_info: 'info-schi',
    url_teaser: 'articole-recomandate',
    url_weather_news_root: 'stiri-meteo',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'indice-uv',
    url_weather_widget: 'widget-meteo',
    url_authors: 'redactia',
    url_weather_reports: 'tendinta-meteo',
    url_faq: 'intrebari-frecvente',
  },
  'ru-UA': {
    url_imprint: 'kontaktyi',
    url_pollen: 'pylca',
    url_privacy: 'politika-konfidentsialnosti',
    url_ticker: 'aktualnyie',
    url_weather: 'stranitsa-pogodyi',
    url_weather_map: 'radar-pogodyi',
    url_weather_symbol_map: 'prognoz-pogodyi',
    url_warning_map: 'pogodnye-preduprezhdeniya',
    url_air_quality: 'kachestvo-vozduha',
    url_ski_info: 'informaciya-o-lyzhnom-sporte',
    url_teaser: 'izbrannoe',
    url_weather_news_root: 'pogodnyie-novosti',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uf-indeks',
    url_weather_widget: 'widget-pogody',
    url_authors: 'avtory',
    url_weather_reports: 'pogodnaia-tendencia',
    url_faq: 'chastye-voprosy',
  },
  'sk-SK': {
    url_imprint: 'impressum',
    url_pollen: 'pelove-spravy',
    url_privacy: 'ochrana-osobnych-udajov',
    url_ticker: 'aktualne',
    url_weather: 'pocasie',
    url_weather_map: 'meteoradar',
    url_weather_symbol_map: 'predpoved-pocasia',
    url_warning_map: 'meteorologicke-vystrahy',
    url_air_quality: 'kvalita-ovzdusia',
    url_ski_info: 'informacie-pre-lyziarov',
    url_teaser: 'top-temy',
    url_weather_news_root: 'spravy-o-pocasi',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-index',
    url_weather_widget: 'widget-pocasie',
    url_authors: 'redaktori',
    url_weather_reports: 'vyvoj-pocasia',
    url_faq: 'najcastejsie-otazky',
  },
  'sl-SI': {
    url_imprint: 'odtis',
    url_pollen: 'cvetni-prah',
    url_privacy: 'zasebnost-podatkov',
    url_ticker: 'novice',
    url_weather: 'vreme',
    url_weather_map: 'vremenski-radar',
    url_weather_symbol_map: 'vremenska-napoved',
    url_warning_map: 'vremenska-opozorila',
    url_air_quality: 'kakovost-zraka',
    url_ski_info: 'smucarske-info',
    url_teaser: 'uredniski-izbor',
    url_weather_news_root: 'novice-o-vremenu',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-indeks',
    url_weather_widget: 'vremenski-pripomocek',
    url_authors: 'redakcija',
    url_weather_reports: 'vremenski-trend',
    url_faq: 'pogosta-vprasanja',
  },
  'sr-Latn-ME': {
    url_imprint: 'otisak',
    url_pollen: 'polen',
    url_privacy: 'privatnost',
    url_ticker: 'novosti',
    url_weather: 'vrijeme',
    url_weather_map: 'vremenski-radar',
    url_weather_symbol_map: 'prognoza-vremena',
    url_warning_map: 'vremenska-upozorenja',
    url_air_quality: 'kvalitet-vazduha',
    url_ski_info: 'ski-info',
    url_teaser: 'izbor-urednika',
    url_weather_news_root: 'vesti-o-vremenu',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-indeks',
    url_weather_widget: 'vreme-vidzet',
    url_authors: 'redakcija',
    url_weather_reports: 'trend-vremena',
    url_faq: 'cesta-pitanja',
  },
  'sr-Latn-RS': {
    url_imprint: 'otisak',
    url_pollen: 'polen',
    url_privacy: 'privatnost',
    url_ticker: 'novosti',
    url_weather: 'vreme',
    url_weather_map: 'vremenski-radar',
    url_weather_symbol_map: 'prognoza-vremena',
    url_warning_map: 'vremenska-upozorenja',
    url_air_quality: 'kvalitet-vazduha',
    url_ski_info: 'ski-info',
    url_teaser: 'izbor-urednika',
    url_weather_news_root: 'vesti-o-vremenu',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-indeks',
    url_weather_widget: 'vreme-vidzet',
    url_authors: 'redakcija',
    url_weather_reports: 'trend-vremena',
    url_faq: 'cesta-pitanja',
  },
  'tr-TR': {
    url_imprint: 'kuenye',
    url_pollen: 'polen',
    url_privacy: 'gizlilikpolitikasi',
    url_ticker: 'hava-durumu-haberi',
    url_weather: 'hava-durumu',
    url_weather_map: 'hava-durumu-radari',
    url_weather_symbol_map: 'hava-tahmini-haritasi',
    url_warning_map: 'siddetli-hava-uyarilari',
    url_air_quality: 'hava-kalitesi',
    url_ski_info: 'kayak-bilgisi',
    url_teaser: 'editors-pick',
    url_weather_news_root: 'hava-haberleri',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uv-indeksi',
    url_weather_widget: 'hava-durumu-widget',
    url_authors: 'authors',
    url_weather_reports: '14-gunluk-hava-durumu',
    url_faq: 'sikca-sorulan-sorular',
  },
  'uk-UA': {
    url_imprint: 'kontakty',
    url_pollen: 'pylok',
    url_privacy: 'polityka-konfidentsiinosti',
    url_ticker: 'aktualni',
    url_weather: 'storinka-pohody',
    url_weather_map: 'radar-pohody',
    url_weather_symbol_map: 'prohnoz-pohody',
    url_warning_map: 'pohodni-poperedzhennia',
    url_air_quality: 'yakist-povitria',
    url_ski_info: 'informatsiia-pro-lyzhnyi-sport',
    url_teaser: 'vybrane',
    url_weather_news_root: 'pohodni-novyny',
    url_product_marketing_root: 'discover-the-app',
    url_uv_index: 'uf-indeks',
    url_weather_widget: 'widget-pohody',
    url_authors: 'avtory',
    url_weather_reports: 'pohodna-tendencia',
    url_faq: 'chasti-zapytannia',
  },
};
