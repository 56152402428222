export enum RoutePathKey {
  weather = 'url_weather',
  imprint = 'url_imprint',
  privacy = 'url_privacy',
  weatherRadar = 'url_weather_map',
  weatherSymbolMap = 'url_weather_symbol_map',
  warningMap = 'url_warning_map',
  ticker = 'url_ticker',
  pollen = 'url_pollen',
  airQuality = 'url_air_quality',
  ski = 'url_ski_info',
  teaser = 'url_teaser',
  weatherNewsRoot = 'url_weather_news_root',
  productMarketingRoot = 'url_product_marketing_root',
  uvIndex = 'url_uv_index',
  weatherWidget = 'url_weather_widget',
  authors = 'url_authors',
  trend = 'url_weather_reports',
  faq = 'url_faq',
}

export enum ConstantRoute {
  error = 'error',
  editorialError = 'editorialError',
  notFound = '404',
  debug = 'debug',
  apps = 'apps',
  home = '',
}
