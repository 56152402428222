import { EARTH_WRAP_LNG, earthDistance } from './crs/earth';
import { LatLngBounds } from './LatLngBounds';
import { formatNum, wrapNum } from './Util';

export class LatLng {
  static fromString(
    latStr: string | null | undefined,
    lngStr: string | null | undefined,
  ): LatLng | null {
    if (latStr != null && lngStr != null) {
      const lat = parseFloat(latStr);
      const lng = parseFloat(lngStr);
      if (!isNaN(lat) && !isNaN(lng)) {
        return new LatLng(lat, lng);
      }
    }
    return null;
  }

  constructor(
    public lat: number,
    public lng: number,
    public alt?: number,
  ) {}

  equals(latLng: LatLng, maxMargin = 1.0e-9): boolean {
    const margin = Math.max(
      Math.abs(this.lat - latLng.lat),
      Math.abs(this.lng - latLng.lng),
    );

    return margin <= maxMargin;
  }

  toString(precision?: number): string {
    return `LatLng(${formatNum(this.lat, precision)}, ${formatNum(
      this.lng,
      precision,
    )})`;
  }

  distanceTo(other: LatLng): number {
    return earthDistance(this, other);
  }

  wrap(): LatLng {
    const lng = wrapNum(this.lng, EARTH_WRAP_LNG, true);
    return new LatLng(this.lat, lng, this.alt);
  }

  toBounds(sizeInMeters: number): LatLngBounds {
    const latAccuracy = (180 * sizeInMeters) / 40075017,
      lngAccuracy = latAccuracy / Math.cos((Math.PI / 180) * this.lat);

    return new LatLngBounds(
      new LatLng(this.lat - latAccuracy, this.lng - lngAccuracy),
      new LatLng(this.lat + latAccuracy, this.lng + lngAccuracy),
    );
  }

  clone(): LatLng {
    return new LatLng(this.lat, this.lng, this.alt);
  }
}

export type LatLngLike =
  | LatLng
  | number[]
  | number
  | { lat: number; lng: number; alt?: number };

export function toLatLng(a: LatLngLike, b?: number, c?: number): LatLng {
  if (a instanceof LatLng) {
    return a;
  } else if (Array.isArray(a)) {
    if (a.length >= 2) {
      return new LatLng(a[0], a[1], a[2]);
    }
  } else if (typeof a === 'object') {
    return new LatLng(a.lat, a.lng, a.alt);
  } else if (b !== undefined) {
    return new LatLng(a, b, c);
  }
  throw new Error('Tried to construct an invalid LatLng object');
}
