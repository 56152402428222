import { OtherLocale } from '../locales/other-locales';
import { Domain } from './domain';

export const otherHostDomainsForLocale: Record<OtherLocale, Domain[]> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  'es-MX': [{ subdomain: 'www', host: 'tiempoyradar.com.mx' }],
  'hi-IN': [{ subdomain: 'www', host: 'mausam.weatherandradar.in' }],

  // Hint: although there *are* PWAs behind the hostname we provide here, this
  // still belongs to "other mappings" rather than "pwa mappings", because there
  // are no specific PWA instances for these locales. The following cases are
  // more like a fallback. If someone e.g. asks for the hostname of "ta-IN", they'll
  // be falling back to the PWA host of "en-IN" and so on
  'ta-IN': [{ subdomain: 'www', host: 'weatherandradar.in' }],
  'sr-Cyrl-ME': [{ subdomain: 'www', host: 'vrijemeradar.me' }],
  'sr-Cyrl-RS': [{ subdomain: 'www', host: 'vremeradar.rs' }],
  /* eslint-enable @typescript-eslint/naming-convention */
};
