import { Transformation } from '../geometry/Transformation';
import { SphericalMercator } from '../projection/Projection.SphericalMercator';
import { CRSEarth } from './CRS.Earth';

export class CRSEPSG3857 extends CRSEarth {
  projection = SphericalMercator;
  transformation = (function () {
    const scale = 0.5 / (Math.PI * SphericalMercator.R);
    return new Transformation(scale, 0.5, -scale, 0.5);
  })();
}
